import React from "react";
import { FaStar } from "react-icons/fa"
import Description from "./description";



export default function TestimonalsProps(props) {
    return (
        <>
            <div className='flex flex-col w-full space-y-3 mt-5 font-main-font'>
                        <div className='flex h-full items-center space-x-5'>
                            <div className='h-28 w-28 rounded-full overflow-hidden'>
                                    <img src={props.img} alt="" />    
                            </div>

                            <div className='flex-col space-y-3'>
                                <div> <h1 className='text-main-gray font-main-font font-bold text-[20px]'>{props.avName} </h1> </div>
                                <div> <h2 className='text-[14px] text-main-light font-light'> {props.avProf} </h2> </div>

                                <div className='text-yellow-500 flex space-x-2'>
                                    <div><FaStar /></div>
                                    <div><FaStar /></div>
                                    <div><FaStar /></div>
                                    <div><FaStar /></div>
                                    <div><FaStar /></div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <Description desc={props.avText} />
                        </div>
                    </div>
        </>
    )
}