import { Swiper, SwiperSlide } from 'swiper/react';
import React,  { useEffect }  from "react"
import 'swiper/css';
import MiniTitle from '../PropsComponents/miniTitle';
import { Parallax, Navigation, Pagination } from 'swiper/modules';
import portimg1 from "../../assets/img/firstbig_files/04_img.jpg";
import portimg2 from "../../assets/img/firstbig_files/05_img.jpg";
import portimg3 from "../../assets/img/firstbig_files/06_img.jpg";
import portimg4 from "../../assets/img/firstbig_files/07_img.jpg";
import PortfolioProps from '../PropsComponents/PortfolioProps';


export default function Portfolio() {



    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('Portfolio');
        
            if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 1.5s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1';

            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    const listPortfolio = [
        {
            img: portimg1, category: "Category", underCat: "Who in the world am I? Ah, that's the great puzzle."
        },
        {
            img: portimg2, category: "Category", underCat: "Who in the world am I? Ah, that's the great puzzle."
        },
        {
            img: portimg3, category: "Category", underCat: "Who in the world am I? Ah, that's the great puzzle."
        },
        {
            img: portimg4, category: "Category", underCat: "Who in the world am I? Ah, that's the great puzzle."
        }
    ]

    return (
        <>

        <div id='Portfolio' className='-translate-x-24 opacity-0 !overflow-hidden'>


            <div className="px-24 mt-36 flex justify-between mx-auto " id='Portfolio'>
                <div>
                    <MiniTitle title={'Portfolio'} />
                </div>
            </div>

            <div className='w-full'>

                <Swiper
                    spaceBetween={0}
                    slidesPerView={1.3}
                    breakpoints={{
                        1091: {
                            slidesPerView: 3,
                            spaceBetween: -220
                        },
                        1360: {
                          slidesPerView: 3,
                          spaceBetween: -90,
                        },
                      }}
                    modules={[Parallax, Navigation, Pagination]}
                    pagination={{
                        type: 'fraction',
                    }}
                    onSwiper={(swiper) => console.log(swiper)}
                >

                    {
                        listPortfolio && listPortfolio.map((item, index) => {
                            return (
                                <SwiperSlide> <PortfolioProps img={item.img} category={item.category} underCat={item.underCat} key={index} /> </SwiperSlide>
                            )
                        })
                    }


                </Swiper>

            </div>
        </div>



        </>
    )
}