import React,  { useEffect }  from "react"
import MiniTitle from '../PropsComponents/miniTitle';
import Logo1 from "../../assets/img/firstbig_files/11_img.png"
import Logo2 from "../../assets/img/firstbig_files/12_img.png"
import Logo3 from "../../assets/img/firstbig_files/13_img.png"
import Logo4 from "../../assets/img/firstbig_files/14_img.png"
import Logo5 from "../../assets/img/firstbig_files/15_img.png"

export default function Clients() {

    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('clients');
            const title2 = document.getElementById('Client');
            if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 1.5s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1';

                title2.style.transition = 'all 1.5s ';
                title2.style.transform = 'translateY(0px)';
                title2.style.opacity = '1';
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <>

            <div className="mt-36 px-24 max-sm:px-12 flex justify-between mx-auto -translate-x-24 opacity-0" id='Client'>
                <div>
                    <MiniTitle title={'Clients'} />
                </div>
            </div>


            <div className='max-w-screen-2xl mt-5 mx-auto translate-y-24 opacity-0' id='clients' >

                <div className='mt-16 flex justify-center lg:justify-start space-y-10 items-center flex-wrap'>
                    <div className='md:w-1/2 lg:w-1/4 flex max-md:space-y-5 justify-center'>
                        <img src={Logo1} alt="" className='max-sm:h-10 max-sm:w-28' />
                    </div>
                    <div className='max-md:w-full  md:w-1/2 lg:w-1/4 flex justify-center'>
                        <img src={Logo2} alt="" className='max-sm:h-10 max-sm:w-28' />
                    </div>
                    <div className='max-md:w-full  md:w-1/2 lg:w-1/4 flex justify-center'>
                        <img src={Logo3} alt="" className='max-sm:h-10 max-sm:w-28' />
                    </div>
                    <div className='max-md:w-full md:w-1/2 lg:w-1/4 flex justify-center'>
                        <img src={Logo4} alt="" className='max-sm:h-10 max-sm:w-28' />
                    </div>
                    <div className='max-md:w-full  md:w-1/2 lg:w-1/4 flex justify-center'  >
                        <img src={Logo5} alt="" className='max-sm:h-10 max-sm:w-28' />
                    </div>
                    <div className='max-md:w-full md:w-1/2 lg:w-1/4 flex justify-center'  >
                        <img src={Logo1} alt="" className='max-sm:h-10 max-sm:w-28' />
                    </div>

                </div>

            </div>

        </>
    );
};