import React,  { useEffect }  from "react"
import GirlPic from "../../assets/img/first.jpg"
import ContactInfo from "../PropsComponents/contactInfo";
import { BiLogoFacebook, BiLogoLinkedin } from 'react-icons/bi';
import { AiOutlineInstagram , AiOutlineBehance } from "react-icons/ai";


export default function FirstComp() {

    // window.onload = function() {

    // }
    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('mainTitle');
            const proffesion = document.getElementById("prof");
            const icons = document.getElementById('icons');
            const info = document.getElementById('maps')
            const pic = document.getElementById("girlpic")
        
            // if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 1.5s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1';


                proffesion.style.transition = 'all 1.5s ';
                proffesion.style.transform = 'translateY(0px)';
                proffesion.style.opacity = '1';

                icons.style.transition = 'all 1.5s ';
                icons.style.transform = 'translateY(0px)';
                icons.style.opacity = '1';

                info.style.transition = 'all 1.5s ';
                info.style.transform = 'translateY(0px)';
                info.style.opacity = '1';

                pic.style.transition = 'all 1.5s ';
                pic.style.transform = 'translateY(0px)';
                pic.style.opacity = '1';
            }

        // };

        window.addEventListener('load', handleScroll);

        return () => {
            window.removeEventListener('load', handleScroll);
        };
    }, []);

    const info = [
        {
            Name: 'Full Name', Desc: 'Luca Rendez'
        },

        {
            Name: 'Birth Date', Desc: '1994-05-05'
        },

        {
            Name: 'Address', Desc: 'London, United Kingdom'
        },

        {
            Name: 'Email', Desc: 'info@domain.com', class: '!text-black hover:cursor-pointer hover:!text-main-light duration-200'
        },

        {
            Name: 'Phone no.', Desc: '+432 533 12 523',  class: '!text-black hover:cursor-pointer hover:!text-main-light duration-200'
        }


]

    return (
        <>
        



            <div className="flex h-full " id="Home">
                <div className="w-full lg:w-[60%] bg-[#f7f7f7] h-[80%] ">
                    <div  className="p-12 md:p-16 lg:px-24 w-[70%] lg:w-[80%]">
                        <h1 className="text-main-gray max-sm:px-2    leading-tight text-[6.2vw] lg:text-[5.2vw] font-black -translate-y-24 opacity-0" id="mainTitle">
                            Hi. I'm Luca Rendez
                        </h1>
                        <p id="prof" className="text-[14px] duration-150 md:text-[16px] px-2 pt-5  text-main-light font-main-font font-light translate-y-24 opacity-0">Photographer and Web Designer.</p>
                    </div>

                    <div className="flex max-xl:flex-wrap max-lg:flex-wrap lg:justify-between w-[80%] duration-150 border-t-2 max-md:mx-12 max-lg:mx-20 lg:mx-24 py-7 translate-y-24 opacity-0" id="maps">
                        {
                            info.map((item, index) => {
                                return (
                                    <ContactInfo Name={item.Name} Desc={item.Desc} class={item.class} key={index} />
                                )
                            })
                        }
                    </div>

                    <div className="h-full  flex space-x-6 py-20 mx-20 px-2 text-xl translate-y-24 opacity-0 " id="icons">
                        <a href='https://www.facebook.com'> <BiLogoFacebook /> </a>
                        <a href='https://www.linkedin.com'> <BiLogoLinkedin /> </a>
                        <a href='https://www.instagram.com'> <AiOutlineInstagram /> </a>
                        <a href='https://www.behance.net'> <AiOutlineBehance /> </a>
                    </div>
                </div>

               <div className="w-[41%] h-[90%] hidden lg:block  md:absolute md:top-0 md:right-0 -translate-y-24 opacity-0 " id="girlpic" >
                    <img src={GirlPic} className="overflow-hidden h-full w-full" alt="" />
                </div>
            </div>
        </>
    )
}