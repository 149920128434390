import React from "react";
import Header from "./Pages/Layouts/header";
import Home from "./Pages/home";
import Footer from "./Pages/Layouts/footer";
import ScrollToTop from "./Pages/ScrollToTop";

function App() {
  return (

    <>
    <ScrollToTop />
    <Header />
    <Home />
    <Footer />
    </>
    
  );
}

export default App;
