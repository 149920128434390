export default function ContactInfo(props) {
    return (
        <>
            <div className="flex flex-wrap md:space-x-5 max-sm:w-full sm:w-1/3 lg:w-1/4 xl:w-1/2">
                <div className={`flex-col space-y-3`}>
                    <div className={`font-main-font font-bold ${props.mobclass}`}>{props.Name}</div>
                    <div className={`text-main-light font-main-font font-normal ${props.class}`}>{props.Desc}</div>
                </div>
            </div>
        </>
    )
}