import React from "react";
import { Link } from "react-scroll";
import {
  Navbar,
  MobileNav,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import Logo from "../../assets/img/Logo/logo.svg";
import { BiSearch } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa";
import { useEffect } from "react";


export default function Example() {


  useEffect(() => {
    const handleScroll = () => {
      const x = document.documentElement.scrollTop;
      const n = document.getElementById('TopNavbar');


      if (x === 0) {
        n.style.background = 'transparent';
        n.style.boxShadow = 'none';
       
      } else {
        n.style.transition = 'all 0.5s';
        n.style.position = 'sticky';
        n.style.top = '0px';
        n.style.background = '#fff';
        n.style.boxShadow = '0 0 10px hsla(0,0%,100%,.08)';
        
      }



    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);







  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 font-main-font font-black">


      <Typography
        as="li"
        variant="small"
        color=""
        className="p-1 font-normal flex items-center space-x-3"
      >
        <div className="relative group">
          <Link to="#" className="flex items-center  hover:cursor-pointer group font-main-font font-bold text-[14px] text-main-light duration-200 hover:text-black">
            Resume
            <FaAngleDown className="group-hover:-rotate-180 duration-300 ml-1" />
          </Link>
          <div className="absolute hidden group-hover:block w-[230px] border py-5  text-main-light text-[14px] font-main-font font-base top-5 bg-[#fff] right-0">
            <ul>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" className="w-full" to="About" spy={true} smooth={true} offset={-100} duration={500}>
                  About Me
                </Link>
              </li>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="WhatWeDo" spy={true} smooth={true} offset={-100} duration={500}>
                  What I do
                </Link>
              </li>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="Experience" spy={true} smooth={true} offset={-100} duration={500}>
                  Experience
                </Link>
              </li>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="Education" spy={true} smooth={true} offset={-100} duration={500}>
                  Education
                </Link>
              </li>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="Portfolio" spy={true} smooth={true} offset={-100} duration={500}>
                  Portfolio
                </Link>
              </li>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="Testimonals" spy={true} smooth={true} offset={-100} duration={500}>
                  Testimonals
                </Link>
              </li>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="Client" spy={true} smooth={true} offset={-100} duration={500}>
                  Clients
                </Link>
              </li>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="Contact" spy={true} smooth={true} offset={-100} duration={500}>
                  Contact Me
                </Link>
              </li>
            </ul>
          </div>
        </div>


      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal  flex items-center space-x-2"
      >

        <div className="relative group">
          <Link to="#" className="flex items-center hover:cursor-pointer group font-main-font font-bold text-[14px] text-main-light duration-200 hover:text-black">
            Blog
            <FaAngleDown className="group-hover:-rotate-180 duration-300 ml-1 " />
          </Link>

          <div className="absolute hidden bg-white  group-hover:block w-[230px] duration-200 py-5 border text-main-light text-[14px] font-main-font font-base top-5 right-0">
            <ul>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="" spy={true} smooth={true} offset={-100} duration={500}>
                  Articles
                </Link>
              </li>
              <li className="py-3 pl-9 hover:cursor-pointer hover:text-main-gray duration-200">
                <Link activeClass="active" to="" spy={true} smooth={true} offset={-100} duration={500}>
                  Blog single post
                </Link>
              </li>
            </ul>
          </div>

        </div>

      </Typography>


    </ul>
  );

  return (
    <>
    <div id="TopNavbar" className="sticky top z-10 h-full !w-full max-lg:px-5 mx-auto border-none rounded-none py-2   items-start">
    <Navbar  className="!border-none !mx-auto !bg-transparent">
        <div className=" sm:px-5 flex items-center justify-between w-full h-full text-blue-gray-900">
          <Typography
            as="a"
            href="#"
            className="mr-4 cursor-pointer py-1.5 font-bold"
          >
            <img src={Logo} className="hover:scale-110 z-30 duration-300 hover:cursor-pointer" alt="" />
          </Typography>
          <div className="flex items-center h-full gap-4 ">
            <div className="mr-4 h-full hidden lg:block">{navList}</div>

            <IconButton
              id="porc"
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="black"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="black"
                  strokeWidth={3}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
          <div className="hidden lg:flex items-center space-x-4">
            <Typography
              as="li"
              variant="small"
              color="blue-gray"
              className="p-1 font-bold list-none"
            >
              <Link to="#" className="flex items-center hover:cursor-pointer">
                <BiSearch className="h-5 w-5 text-black font bold" />
              </Link>
            </Typography>
            <Typography
              as="li"
              variant="small"
              color="blue-gray"
              className="p-1 font-normal list-none "
            >
              <Link to="/" className="flex items-center hover:cursor-pointer bg-main-gray text-[14px] font-main-font font-bold text-white px-16 py-5">
                Download CV
              </Link>
            </Typography>
          </div>
        </div>
        <MobileNav open={openNav}>
          {navList}

        </MobileNav>
      </Navbar>
    </div>


    </>
  );
}