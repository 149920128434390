export default function ArticlesProps(props) {
    return(
        <>
        <div className="">
                <div className="overflow-hidden">
                    <img src={props.image} alt="" className="h-full w-full hover:scale-110 duration-300 hover:cursor-pointer" />
                </div>
                <div className="flex flex-col font-main-font space-y-5 mt-8">
                    <div>
                        <h1 className="text-main-gray font-main-font font-bold text-[20px]">{props.arTitle}</h1>
                    </div>
                    <div className="">
                        <p className='text-[14px] font-light text-main-light'>{props.arCat}</p>
                    </div>
                    <div>
                        <p className="text-[16px]  text-main-light font-medium">
                            {props.arText}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}