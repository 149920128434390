import React from "react";


export default function SocialProps(props) {
    return (
        <>
            <div id={props.id} className={`opacity-0 bg-[#f7f7f7] flex flex-col justify-center items-center py-14 w-full lg:w-1/4 ${props.class} `}>
                <div className="text-3xl text-main-gray">
                    {props.Icon}
                </div>
                <div className=""> 
                    <p className="text-[16px] text-main-gray font-main-font font-normal">
                        {props.text}
                    </p>
                </div>
            </div>
        </>
    )
}