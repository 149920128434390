import { Swiper, SwiperSlide } from 'swiper/react';
import React,  { useEffect }  from "react"
import 'swiper/css';
import MiniTitle from '../PropsComponents/miniTitle';
import Avatar1 from "../../assets/img/firstbig_files/08_img.jpg";
import Avatar2 from "../../assets/img/firstbig_files/09_img.jpg";
import Avatar3 from "../../assets/img/firstbig_files/10_img.jpg";
import { Parallax, Pagination, Navigation } from 'swiper/modules';
import TestimonalsProps from '../PropsComponents/testimonalsProps';

export default function Testimonals() {


    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('Testimonals');
        
            if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 1.5s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1';

            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const listTest = [
        {
            img: Avatar1, avName: "Rosa Ford", avProf: "Ceo at company", avText: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo."
        },
        {
            img: Avatar2, avName: "Henry Richardson", avProf: "Ceo at company", avText: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo."
        },
        {
            img: Avatar3, avName: "Luca Reynolds", avProf: "Ceo at company", avText: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo."
        },
        {
            img: Avatar2, avName: "Henry Richardson", avProf: "Ceo at company", avText: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo."
        }
    ]

    return (
        <>

            <div id='Testimonals' className='-translate-x-24 opacity-0'>

                <div className='bg-[#f7f7f7] mt-36 py-28' >
                    <div className="px-24 flex justify-between mx-auto">
                        <div>
                            <MiniTitle title={'Testimonals'} />
                        </div>
                    </div>


                    <div className='w-[90%] mt-8 border-t-2 py-4 mx-auto'>
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            breakpoints={{
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }}
                            parallax={true}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[Parallax, Navigation, Pagination]}
                            onSwiper={(swiper) => console.log(swiper)}
                        >

                            {
                                listTest && listTest.map((item, index) => {
                                    return (
                                        <SwiperSlide> <TestimonalsProps img={item.img} avName={item.avName} avProf={item.avProf} avText={item.avText} key={index} /> </SwiperSlide>
                                    )
                                })
                            }


                        </Swiper>


                    </div>
                </div>
            </div>



        </>
    )
}