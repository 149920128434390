import { Swiper, SwiperSlide } from 'swiper/react';
import React,  { useEffect }  from "react"
import 'swiper/css';
import 'swiper/css/pagination';
import MiniTitle from '../PropsComponents/miniTitle';
import { Parallax, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';

import SliderInfo from '../PropsComponents/sliderInfo';

export default () => {


    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('Experience');
            const porc = document.getElementById('porc')
            if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 2s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1';

            
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    const list = [
        {
            title: 'Senior photo editor', time: "Full time", los: '“Company”-Los Angeles-2015-2016', text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo", id: '1', class: 'duration-[1s]'
        },
        {
            title: 'Senior photo editor', time: "Full time", los: '“Company”-Los Angeles-2015-2016', text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo", id: '2', class: 'duration-[2s]'
        },
        {
            title: 'Senior photo editor', time: "Full time", los: '“Company”-Los Angeles-2015-2016', text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo", id: '3', class: 'duration-[3s]'
        },
        {
            title: 'Senior photo editor', time: "Full time", los: '“Company”-Los Angeles-2015-2016', text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo", id: '4', class: 'duration-[1s]'
        }
    ]









    

    return (

        <>

        <div id='Experience' className='-translate-x-24 opacity-0 overflow-hidden'>


            <div className="mt-24 px-24 flex justify-between mx-auto " id='Experience'>
                <div>
                    <MiniTitle title={'Experience'} />

                </div>

                
            </div>


            <div id='porc' className='w-[90%] mt-5 border-t-2 py-6 mx-auto'>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    breakpoints={{
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                      }}
                    parallax={true}
                    pagination={{
                        type: 'fraction',
                    }}
                    navigation={true}
                    modules={[Parallax, Navigation, Pagination]}
                    onSwiper={(swiper) => console.log(swiper)}
                >

                    {
                        list && list.map((item, index) => {
                            return (
                                <SwiperSlide> <SliderInfo time={item.time} class={item.class} id={item.id} title={item.title} los={item.los} text={item.text} key={index} /> </SwiperSlide>
                            )
                        })
                    }


                </Swiper>

            </div>
        </div>

        </>
    );
};