import { Swiper, SwiperSlide } from 'swiper/react';
import React,  { useEffect }  from "react"
import 'swiper/css';
import 'swiper/css/navigation';
import MiniTitle from '../PropsComponents/miniTitle';
import { Parallax, Navigation, Pagination } from 'swiper/modules';
import SliderInfo from '../PropsComponents/sliderInfo';


export default function Education() {



    const EdList = [
        {
            title: 'University of LA', los: '“Company”-Los Angeles-2015-2016', text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo"
        },
        {
            title: 'Web Design Courses', los: '“Company”-Los Angeles-2015-2016', text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo"
        },
        {
            title: 'Photography Courses', los: '“Company”-Los Angeles-2015-2016', text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo"
        },
        {
            title: 'Marketing Courses', los: '“Company”-Los Angeles-2015-2016', text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo"
        }
    ]



    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('Education');
        
            if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 1.5s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1';

            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>

            <div className='bg-[#f7f7f7] mt-36 py-20 -translate-x-24 opacity-0 overflow-hidden' id='Education'>
                <div className="px-24 flex justify-between mx-auto">
                    <div>
                        <MiniTitle title={'Education'}/>
                    </div>

                </div>


                <div className='w-[90%]  border-t-2 py-4 mx-auto'>
                    <Swiper
                       spaceBetween={50}
                       slidesPerView={1}
                       breakpoints={{
                           1024: {
                             slidesPerView: 3,
                             spaceBetween: 10,
                           },
                         }}
                       parallax={true}
                       pagination={{
                           type: 'fraction',
                       }}
                       navigation={true}
                       modules={[Parallax, Navigation, Pagination]}
                       onSwiper={(swiper) => console.log(swiper)}
                    >

                        {
                            EdList && EdList.map((item, index) => {
                                return (
                                    <SwiperSlide> <SliderInfo title={item.title} los={item.los} text={item.text} key={index} /> </SwiperSlide>
                                )
                            })
                        }


                    </Swiper>

                </div>
            </div>

        </>
    )
}