import MiniTitle from "../PropsComponents/miniTitle";
import ContactInfo from "../PropsComponents/contactInfo";
import React,  { useEffect }  from "react"

export default function Contact() {

    const info = [
        {
            Name: 'Address', Desc: 'London, United Kingdom', mobclass: '!max-sm:text-red-500'
        },

        {
            Name: 'Email', Desc: 'info@domain.com', class: '!text-black hover:cursor-pointer hover:!text-main-light duration-300', mobclass: 'text-main-gray'
        },

        {
            Name: 'Phone no.', Desc: '+432 533 12 523', class: '!text-black hover:cursor-pointer hover:!text-main-light duration-300', mobclass: 'text-main-gray'
        },



    ]



    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('Contact');
            const label = document.getElementById('form');
            const description = document.getElementById('desc');
            if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 1.5s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1';

                label.style.transition = 'all 1.5s ';
                label.style.transform = 'translateY(0px)';
                label.style.opacity = '1';

                description.style.transition = 'all 1.5s ';
                description.style.transform = 'translateY(0px)';
                description.style.opacity = '1';
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return (
        <>

        <div className="overflow-hidden">


            <div className="mt-36 px-24 max-sm:px-12 flex justify-between mx-auto translate-x-24 opacity-0 " id="Contact">
                <div>
                    <MiniTitle title={'Contact me'} />
                </div>
            </div>



            <div className="mt-10 w-full  flex flex-col max-md:px-12 lg:flex lg:flex-row md:space-x-10 md:px-12 max-sm:px-5 lg:px-24 ">
                <div className="w-full lg:w-1/2 font-main-font flex flex-col space-y-10 -translate-y-24 opacity-0" id="form">
                    <div className="flex flex-col space-y-5">
                        <h1 className="text-main-gray font-main-font font-bold text-[16px]">Name</h1>
                        <input type="text" placeholder="Enter your name" className="py-4 px-5 w-full bg-[#f7f7f7] focus:border-none focus:border-red-500 " />
                    </div>
                    <div className="flex flex-col space-y-5">
                        <h1 className="text-main-gray font-main-font font-bold text-[16px]">Email</h1>
                        <input type="text" placeholder="Enter your email" className="py-4 px-5 w-full bg-[#f7f7f7]" />
                    </div>
                    <div className="flex flex-col space-y-5">
                        <h1 className="text-main-gray font-main-font font-bold text-[16px]">Message</h1>
                        <textarea name="" id="" cols="40" rows="5" placeholder="Enter your message" className=" py-4 px-5 w-full bg-[#f7f7f7] " ></textarea>
                    </div>
                    <div>
                        <button className="bg-main-gray py-4 px-12 text-[14px] font-bold text-white hover:bg-black duration-500">Send Message</button>
                    </div>
                </div>


                <div className="w-full max-sm:w-[100%] max-sm:mt-5 max-lg:py-10 lg:w-1/2 -translate-y-72 opacity-0" id="desc">
                    <div className="bg-contact-bg h-full bg-contain flex flex-col justify-end pb-10 bg-center ">

                        <div className="flex w-[90%] !text-[13px] max-sm:flex-wrap max-sm:w-full max-sm:justify-center max-sm:space-x-5 lg:w-[80%] border-t-2 relative py-6 justify-around after:content-['•'] after:absolute after:-top-6 after:text-[31px]">
                            {
                                info.map((item, index) => {
                                    return (
                                        <ContactInfo Name={item.Name} Desc={item.Desc} class={item.class} mobclass={item.mobclass} key={index} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>


        </>
    )
}