import React,  { useEffect }  from "react"
import MiniTitle from "../PropsComponents/miniTitle";
import { FaCamera, FaHashtag, FaShoppingCart, FaPenNib } from "react-icons/fa";
import SocialProps from "../PropsComponents/socialprops";

export default function WhatWeDo() {

    const Social = [
        {
            Icon: <FaCamera />, text: 'Photography', id: '1', class: 'duration-[1s]'
        },
        {
            Icon: <FaHashtag />, text: 'Social Media', id: '2', class: 'duration-[2s]'
        },
        {
            Icon: <FaShoppingCart />, text: 'Ecommerce', id: '3', class: 'duration-[3s]'
        },
        {
            Icon: <FaPenNib />, text: 'Design', id: '4', class: 'duration-[3.5s]'
        },

    ]


    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('WhatWeDo');

            if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 1.5s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1'
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    useEffect(() => {
        const handleScroll = () => {
            Social.forEach((item) => {
                const x = document.documentElement.scrollTop;
                const n = document.getElementById(item.id);
                if (x + 700 > n.offsetTop) {
                    n.classList.add('n-transition');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);






    return (
        <>
            <div className="mt-36 px-24 max-sm:px-10 max-sm:mt-16 translate-y-24 opacity-0" id="WhatWeDo">
                <MiniTitle title={'What I do?'} />
            </div>

            <div className="px-24 max-sm:mt-16 lg:space-x-5 max-lg:space-y-5 max-lg:flex max-lg:flex-col items-center lg:w-full lg:flex lg:flex-row lg:justify-between lg:mt-10 ">
                {
                    Social.map((item, index) => {
                        return (
                            <SocialProps Icon={item.Icon} id={item.id} text={item.text} class={item.class} key={index} />
                        )
                    })
                }
            </div>
        </>
    )
}