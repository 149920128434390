import MiniTitle from "../PropsComponents/miniTitle";
import Boy from "../../assets/img/firstbig_files/16_img.jpg"
import Boy2 from "../../assets/img/firstbig_files/17_img.jpg"
import Boy3 from "../../assets/img/firstbig_files/19_img.jpg"
import ArticlesProps from "../PropsComponents/articlesProps";
import React,  { useEffect }  from "react"

export default function Articles() {

    const articles = [
        { image: Boy, arTitle: "The beauty of sunrise", arCat: "Category", arText: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium…" },
        { image: Boy2, arTitle: "The beauty of sunrise", arCat: "Category", arText: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium…" },
        { image: Boy3, arTitle: "The beauty of sunrise", arCat: "Category", arText: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium…" }
    ]


    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const title = document.getElementById('articles');
            const map = document.getElementById('artmap');

            if (x + 800 > title.offsetTop) {
                title.style.transition = 'all 1.5s ';
                title.style.transform = 'translateY(0px)';
                title.style.opacity = '1'

                map.style.transition = 'all 1.5s ';
                map.style.transform = 'translateY(0px)';
                map.style.opacity = '1'
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
        
        <div className="overflow-hidden">


            <div className="mt-36 px-24 flex justify-between mx-auto -translate-x-24 opacity-0" id="articles">
                <div>
                    <MiniTitle title={'My articles'} />
                </div>
            </div>



            <div className="max-lg:px-24 max-sm:px-12 w-full h-full lg:w-[90%] lg:h-[90%] lg:mx-auto flex flex-col lg:flex lg:flex-row lg:justify-between lg:items-center lg:space-x-12 max-lg:space-y-10 mt-10 translate-x-24 opacity-0" id="artmap">
                {
                    articles && articles.map((item, index) => {
                        return (
                            <ArticlesProps image={item.image} arTitle={item.arTitle} arCat={item.arCat} arText={item.arText} key={index} />
                        )
                    })
                }
            </div>

        </div>



        </>
    )
}