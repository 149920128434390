
import AboutGirl from "../../assets/img/firstbig_files/02_img.jpg";
import Sign from "../../assets/img/firstbig_files/03_img.png";
import MiniTitle from "../PropsComponents/miniTitle";
import Description from "../PropsComponents/description";
import React, { useEffect } from 'react';

export default function About() {


    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const slider = document.getElementById('slider');
            const sign = document.getElementById('sign');
            const girl = document.getElementById('girl')
            if (x + 800 > slider.offsetTop) {
                slider.style.transition = 'all 1.5s ';
                slider.style.transform = 'translateY(0px)';
                slider.style.opacity = '1'

                sign.style.transition = 'all 1.5s ';
                sign.style.transform = 'translateY(0px)';
                sign.style.opacity = '1'

                girl.style.transition = 'all 1.5s ';
                girl.style.transform = 'translateX(0px)';
                girl.style.opacity = '1'
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div className="mt-32 max-lg:space-y-10 max-lg:w-full lg:w-[75%] h-[90%] max-lg:flex max-lg:flex-col max-lg:items-center lg:flex items-center mx-auto" id="About">
                <div id="girl"  className="w-1/2 max-sm:w-full max-sm:px-6 -translate-x-56 opacity-0" >
                    <div className="relative">
                        <img src={AboutGirl} alt="" />
                        <div id="sign" className="absolute -bottom-10 right-0 translate-y-44">
                            <img src={Sign} alt="" />
                        </div>
                    </div>
                </div>
                <div id="slider" className="w-1/2 max-sm:w-full max-sm:px-6 space-y-10 px-5 flex-col translate-y-56 opacity-0">
                    <MiniTitle title={'About Me'} />

                    <MiniTitle mini={'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.'} />

                    <Description desc={'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam.'} />
                </div>
            </div>
        </>
    )
}