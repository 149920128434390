



export default function PortfolioProps(props) {
    return (
        <>
              <div className='w-auto h-auto font-main-font'>
                    <div className='relative overflow-hidden '>
                        <div className='relative hover:scale-110 hover:cursor-pointer duration-300 '>
                            <img src={props.img} alt="" />
                            <div className='absolute top-0 left-0 h-full w-full opacity-20 bg-main-gray hover:opacity-50 hover:duration-300'>

                            </div>
                        </div>
                        <div className='absolute top-[50%] left-[35%] translate-x-[-50%] translate-y-[-50%]'>
                            
                                <p className='text-white opacity-60 text-[20px] font-bold'>
                                    {props.category}
                                </p>
                                
                                <h1 className='text-white text-[29px] font-bold'>
                                    {props.underCat}
                                </h1>
                            
                        </div>
                    </div>
                </div>
        </>
    )
}