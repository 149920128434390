import React from "react";
import FirstComp from "./Components/firstComp";
import About from "./Components/about";
import WhatWeDo from "./Components/whatWeDo";
import Experience from "./Components/experience";
import Education from "./Components/education";
import Portfolio from "./Components/portfolio";
import Testimonals from "./Components/testimonals";
import Clients from "./Components/clients";
import Contact from "./Components/contact";
import Articles from "./Components/articles";

export default function Home() {
    return(
        <>
            <FirstComp />
            <About />
            <WhatWeDo />
            <Experience />
            <Education />
            <Portfolio />
            <Testimonals />
            <Clients />
            <Contact />
            <Articles />
        </>
    )
}