import ContactInfo from "./contactInfo";
import Description from "./description";



export default function SliderInfo(props) {
    return (
        <>
            <div id={props.id} className={`flex flex-col w-full space-y-3 mt-5 font-main-font ${props.class}`}>
                <div className='text-[12px] font-medium bg-[#f7f7f7] px-4 py-2 w-fit'>{props.time}</div>
                <div className='text-[20px] '> <ContactInfo Name={props.title}/> </div>
                <div className='text-[14px] font-light'><ContactInfo Desc={props.los} /> </div>
                <div className='text-[16px]'><Description desc={props.text} /> </div>
            </div>
        </>
    )
}