



export default function Footer() {
    return (
        <>
        
            <div className="md:px-24 max-sm:px-12 py-8 mt-16 flex bg-[#f7f7f7] font-main-font text-main-light justify-between mx-auto">
                <div>
                    <p>© 2023 Personal Resume vCard React JS Template by <span className="text-black hover:cursor-pointer hover:text-main-light duration-300">Adveits</span> </p>
                </div>
            </div>
        </>
    )
}